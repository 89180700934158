import React from 'react';
import { Router, Link, navigate } from "@reach/router";

import ObjectReportOverview from "./components/ObjectReportOverview";
import AllReportOverview from "./components/AllReportOverview";

import ErrorBoundary from './components/states/ErrorBoundary';

import "./assets/tailwind.css";

const Wrapper = ({children}) => (
  <>
    {children}
  </>
);
// Container Component
class App extends React.Component {

  render() {

    return (
      <ErrorBoundary>
        <Router>
          <Wrapper path="/">
            <AllReportOverview path="/" />
            <ObjectReportOverview path="/:objectType/:objectId" />
          </Wrapper>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default App;