import React from "react";
import { DateTime } from "luxon";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class MonthlyRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    };
  }

  toCurrency(number) {
    try {
      const formatter = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: this?.props?.currency ? this.props.currency : "USD",
      });
      number = formatter.format(number);
      return number;

    } catch (error) {
      return "";
    }
  }

  render() {
    const paidTotal = this.props.data?.reduce((acc, val) => acc + val.paid, 0);
    const sentTotal = this.props.data?.reduce((acc, val) => acc + val.sent, 0);
    const authorisedTotal = this.props.data?.reduce((acc, val) => acc + val.authorised, 0);
    const draftTotal = this.props.data?.reduce((acc, val) => acc + val.draft, 0);

    return (
      <ResponsiveContainer width="95%" height={400}>
        <BarChart className="p-10 text-xs" data={this.props.data}>
          <XAxis
            reversed={false}
            dataKey="date"
            padding={{ top: 30 }}
            tickFormatter={(date) => DateTime.fromJSDate(date).toFormat("MMM yyyy") }
          />
          <YAxis
            width={110}
            tickFormatter={this.toCurrency.bind(this)}
          />
          <Tooltip cursor={false} formatter={this.toCurrency.bind(this)} />
          <Legend 
            align="left"
            verticalAlign="top"
          />
          {draftTotal > 0 && 
            <Bar
              dataKey="draft"
              name="Draft"
              stackId="a"
              fill="#FB9C86"
              cursor="pointer"
              onClick={(data, index) => this.props.filterTable("draft", data.date)}
            />  
          }
          {paidTotal > 0 &&
            <Bar
              dataKey="paid"
              name="Paid"
              stackId="a"
              fill="#B4A0E3"
              cursor="pointer"
              onClick={(data, index) => this.props.filterTable("paid", data.date)}
            />
          }
          {authorisedTotal > 0 &&
            <Bar
              dataKey="authorised"
              name="Authorised"
              stackId="a"
              fill="#4FCCD2"
              cursor="pointer"
              onClick={(data, index) => this.props.filterTable("authorised", data.date)}
            />
          }
          {sentTotal > 0 &&
            <Bar
              dataKey="sent"
              name="Sent"
              stackId="a"
              fill="#F5C78E"
              cursor="pointer"
              onClick={(data, index) => this.props.filterTable("sent", data.date)}
            />
          }
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default MonthlyRevenue;



