import React from 'react';

import InvoiceList from "./reports/InvoiceList";
import { DateTime } from "luxon";
import ReportOverview from './ReportOverview';
import { ReactComponent as InvoiceStackLogo } from "../assets/invoice-stack-icon.svg";

const token = new URLSearchParams(document.location.search).get("token");
const api = process.env.REACT_APP_API_SRC;

class ObjectReportOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      startDate: false,
      endDate: false,
      reportData: null,
      hideAllReports: true,
    };
  }
  componentDidMount = async () => {
    this.setState({ status: "loading" });

    try {
      const reportData = await this.getReportData();
      const currency = reportData?.invoices?.[0]?.settings?.currency
        ? reportData?.invoices?.[0]?.settings?.currency
        : "USD";
      let hideAllReports;

      try {
        const preferences = await this.getPreferences();
        hideAllReports = !!preferences.preferences?.hideAllRevenueReports;
      } catch (resp) {
        // The safest option is to hide all reports if the org's preference is unknown.
        hideAllReports = true;
      }

      const status = reportData?.invoices.length ? "complete" : "no_invoices";

      this.setState({ status, reportData, currency, hideAllReports });
    } catch (response) {
      const errorMessage = response.body ? await response.json() : null;

      if (errorMessage?.error === "TokenExpiredError") {
        this.setState({ status: "token_expired_error" });
      } else {
        this.setState({ status: "error" });
      }
    }
  };

  async getPreferences() {
    const response = await fetch(`${api}/reports/preferences/get`, {
      headers: { "Content-Type": "application/json", token: token },
    });

    if (response.status !== 200) throw response;

    return await response.json();
  }

  async getReportData() {
    const start = DateTime.fromJSDate(this.state.startDate).toISODate();
    const end = DateTime.fromJSDate(this.state.endDate).toISODate();
    const { objectType, objectId } = this.props;

    const response = await fetch(
      api +
        `/reports/get/${objectType}/${objectId}/0?` +
        (start && end ? new URLSearchParams({ start, end }) : ""),
      {
        headers: { "Content-Type": "application/json", token: token },
      }
    );

    if (response.status !== 200) throw response;

    return await response.json();
  }

  async dateChange(ranges) {
    this.setState(
      {
        status: "loading",
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      async () => {
        const reportData = await this.getReportData();

        this.setState({
          status: "complete",
          reportData,
        });
      }
    );
  }

  async filterTable(status, date) {
    date = DateTime.fromJSDate(date);
    let invoices = this.state.reportData.invoices;
    let filteredInvoices = invoices.filter((invoice) => {
      let invoiceDate = DateTime.fromISO(invoice.date);
      return (
        invoice.status.toLowerCase() === status &&
        invoiceDate.hasSame(date, "month") &&
        invoiceDate.hasSame(date, "year")
      );
    });
    this.setState({
      filteredInvoices: filteredInvoices,
      filterString:
        "Showing filtered results: " +
        status.toUpperCase() +
        " invoices for " +
        date.toFormat("MMMM yyyy"),
    });
  }

  async resetTableFilter() {
    this.setState({ filteredInvoices: null });
  }

  breadCrumbs = (title) => {
    return (
      <div className="">
        <div className="border border-gray-200 rounded-md w-fit">
          <ul className="flex flex-row gap-2">
            <li className="flex items-center gap-2 pl-3 text-sm">
              <InvoiceStackLogo className="w-5 h-5" />
              <span>Invoice Stack</span>
            </li>
            {this.state.hideAllReports === false && (
              <li className="text-sm">
                <a
                  href={`/?token=${token}`}
                  className="flex items-center gap-2 hover:text-primary "
                >
                  <svg
                    className="flex-shrink-0 w-5 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span>All Revenue</span>
                </a>
              </li>
            )}
            <li className="flex items-center gap-2 pr-3 text-sm">
              <svg
                className="flex-shrink-0 w-5 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <span>
                {this.state.reportData.objectData.type === "company" ?
                  this.state.reportData.objectData.properties.name : 
                  `${this.state.reportData.objectData.properties.firstname} ${this.state.reportData.objectData.properties.lastname}`
                }
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  table = () => {
    return (
      <InvoiceList
        data={
          this.state?.filteredInvoices
            ? this.state.filteredInvoices
            : this.state.reportData.invoices
        }
        isFiltered={this.state?.filteredInvoices}
        filterString={this.state?.filterString}
        resetTableFilter={this.resetTableFilter.bind(this)}
        currency={this.state.currency}
      />
    );
  };

  title = () => {
    return this.state.reportData.objectData.type === "company" ? (
      <div className="flex items-center gap-3">
        {this.state.reportData.objectData.properties?.domain && (
          <img
            className="w-10 h-auto"
            alt={this.state.reportData.objectData.properties.name}
            src={`https://logo.clearbit.com/${this.state.reportData.objectData.properties?.domain}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "https://www.invoicestack.co/sites/default/files/favicon_0.png";
            }}
          />
        )}
        {this.state.reportData.objectData.properties.name}
      </div>
    ) : (
      `${this.state.reportData.objectData.properties.firstname} ${this.state.reportData.objectData.properties.lastname}`
    );
  };

  render() {
    const title = this.state.reportData ? this.title() : "";
    const breadCrumbs = this.state.reportData ? this.breadCrumbs(title) : null;
    const table = this.state.reportData ? this.table() : null;

    return (
      <ReportOverview
        title={title}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        dateChange={this.dateChange.bind(this)}
        breadCrumbs={breadCrumbs}
        reportData={this.state.reportData}
        currency={this.state.currency}
        filterTable={this.filterTable.bind(this)}
        type={this.props.objectType}
        table={table}
        status={this.state.status}
      />
    );
  }
}

export default ObjectReportOverview;