import React from "react";
import NumberDisplay from "../fields/NumberDisplay";

class RevenueBlocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  render() {
    return (
      <dl className="grid grid-cols-1 mt-5 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Paid Revenue</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            <NumberDisplay value={this.props.paidTotal} currency={this.props.currency} />
          </dd>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Future Revenue</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-green-800">
            <NumberDisplay value={this.props.futureTotal} currency={this.props.currency} />
          </dd>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Overdue Revenue</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-red-800">
            <NumberDisplay value={this.props.overdueTotal} currency={this.props.currency} />
          </dd>
        </div>
      </dl>
    );
  }
}

export default RevenueBlocks;
