import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from "react-table"; // new
import React from "react";
import { CSVLink } from "react-csv";

import { ChevronDownIcon, ChevronUpIcon, DownloadIcon } from "@heroicons/react/solid";

import { SearchIcon } from "@heroicons/react/outline";

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div>
      <div className="relative rounded-md shadow-sm">
        <input
          type="text"
          name="account-number"
          id="account-number"
          className="block w-full pr-10 border-gray-300 rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100 sm:text-sm"
          placeholder={`Search ${count} records...`}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}

function Table({ columns, data, title, description, isFiltered, filterString, resetTableFilter }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy
  );

  const getCSVData = () => {
    const headers = headerGroups.map(headerGroup => 
      headerGroup.headers.map(h => ({
        label: h.Header,
        key: h.id
      }))
    );

    const data = rows.map(row => row.values);

    return { headers: headers.pop(), data };
  }

  const csvData = getCSVData();

  // Render the UI for your table
  return (
    <div className="w-full p-2">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        </div>
        <div className="flex gap-5 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <CSVLink
            className="relative flex items-center gap-3 px-5 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
            filename={`${title}.csv`}
            headers={csvData.headers}
            data={csvData.data}
          >
            <DownloadIcon className="w-5 h-5 -ml-1 text-gray-700" aria-hidden="true" />
            Download
          </CSVLink>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      {isFiltered && (
        <div className="p-4 mt-2 rounded-md bg-blue-50 ">
          <div className="flex">
            <div className="flex-shrink-0"></div>
            <div className="flex-1 ml-3 md:flex md:justify-between">
              <p className="text-sm text-blue-700">{filterString}</p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <button
                  onClick={resetTableFilter}
                  className="font-medium text-blue-700 whitespace-nowrap hover:text-blue-600"
                >
                  Reset Filter
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col w-full h-screen mt-8 overflow-scroll rounded-md">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <table {...getTableProps()} className="min-w-full">
                <thead className="rounded-lg">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={
                            " sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-xs font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell0"
                          }
                        >
                          <div href="#" className="inline-flex items-center group">
                            {column.render("Header")}
                            <span className="flex-none ml-2 text-gray-900 bg-gray-200 rounded group-hover:bg-gray-300">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ChevronDownIcon className="w-5 h-4" aria-hidden="true" />
                                ) : (
                                  <ChevronUpIcon className="w-5 h-4" aria-hidden="true" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white">
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={(i % 2 == 0 ? "bg-white " : "bg-gray-50 ") + "hover:bg-gray-100"}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className="px-3 py-4 text-xs text-gray-500 border-b border-gray-200 whitespace-nowrap"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
